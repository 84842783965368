import { ServiceBinding, ServiceProvider } from '@wix/thunderbolt-symbols'
import { ServicesManager } from '@wix/services-manager'
import { ServiceDefinition } from '@wix/services-manager/types'
import { getConfig } from './utils'

export const registerServices = (
	servicesManager: ServicesManager,
	serviceProviders: Array<ServiceProvider>,
	siteFeaturesConfigs: { [featureName: string]: { [key: string]: any } },
	pageFeatureConfigs?: { [featureName: string]: { [key: string]: any } }
): void => {
	serviceProviders.forEach((serviceProvider: ServiceProvider) => {
		const config = getConfig(
			serviceProvider.getConfig,
			siteFeaturesConfigs[serviceProvider.featureName],
			(pageFeatureConfigs ?? {})[serviceProvider.featureName]
		)
		const definition = serviceProvider.definition as ServiceDefinition<any, any>

		registerService(servicesManager, { definition, impl: serviceProvider.impl, config })
	})
}

export const registerService = (servicesManager: ServicesManager, service: ServiceBinding<any, any>): void => {
	if (service.definition && !servicesManager.hasService(service.definition)) {
		servicesManager.addService(service.definition, service.impl, service)
	}
}

import { FeatureName, ServiceName } from '@wix/thunderbolt-symbols'

export const getServicesToLoad = (featureNames: Array<FeatureName>): Array<ServiceName> =>
	featureNames.filter((featureName: FeatureName) => isService(featureName)) as Array<ServiceName>

export const isService = (featureName: string) => featureName.endsWith('Service')

export const getConfig = (getConfigFn: Function | undefined, siteConfig: any, pageConfig: any) => {
	const defaultConfig = {
		siteConfig,
		pageConfig,
	}

	return getConfigFn ? getConfigFn(defaultConfig) : defaultConfig
}

import { BootstrapData } from '../../types'
import { BOOTSTRAP_DATA, MODELS_API, SERVICES_LOADER } from './moduleNames'
import { createServicesManager, createServicesMap, ServicesManager } from '@wix/services-manager'
import { FeatureName, IModelsAPI, ServiceBinding, ServiceName, ServiceProvider } from '@wix/thunderbolt-symbols'
import { serviceLoaders } from '../../serviceLoaders'
import { getConfig, getServicesToLoad, registerService } from 'feature-service-registrar'
import _ from 'lodash'

export interface IServices {
	init: () => Promise<void>
	getService: ServicesManager['getService']
}

const ServicesLoader = (bootstrapData: BootstrapData, modelsApi: IModelsAPI): IServices => {
	const { siteServicesConfigs, siteServicesNames } = bootstrapData
	const pageFeatureConfigs = modelsApi.getFeaturesPageConfigs()
	const servicesManager = createServicesManager(createServicesMap([]))

	return {
		init: async () => {
			const servicesToLoad = getServicesToLoad(_.union(_.keys(pageFeatureConfigs), _.keys(siteServicesNames)) as Array<FeatureName>)
			const services: Array<ServiceBinding<any, any>> = await Promise.all(
				servicesToLoad.map((serviceName: ServiceName) => {
					const serviceLoader = serviceLoaders[serviceName]
					if (!serviceLoader) {
						console.error(`Service loader for ${serviceName} is not defined`)
						return Promise.resolve({ definition: null, config: null, impl: null })
					}

					return serviceLoader()
						.then((serviceProvider: ServiceProvider) => {
							const config = getConfig(serviceProvider.getConfig, siteServicesConfigs[serviceName], pageFeatureConfigs[serviceName])

							return {
								definition: serviceProvider.definition,
								config,
								impl: serviceProvider.impl,
							}
						})
						.catch((e) => {
							console.error(`Failed to load service ${serviceName}`, e)
							return { definition: null, config: null, impl: null }
						})
				})
			)

			services.forEach((service: ServiceBinding<any, any>) => {
				registerService(servicesManager, service)
			})
		},
		getService: (serviceDefinition) => {
			if (!servicesManager) {
				throw new Error('Service manager is not initialized')
			}
			return servicesManager.getService(serviceDefinition)
		},
	}
}

export default {
	factory: ServicesLoader,
	deps: [BOOTSTRAP_DATA, MODELS_API],
	name: SERVICES_LOADER,
}
